import React, { useMemo } from 'react';
import { SuppliesWarning } from 'src/components/atom';
import { DeviceDetailsTab } from 'src/components/molecule';
import { DetailsAccordion } from 'src/components/organism';
import { useShellRootContext } from 'src/contexts/ShellRoot';

export const DetailsMainTemplate = (props) => {
  const { showDetailsTab } = useShellRootContext();

  const renderMemoDeviceDetailsTab = useMemo(() => {
    return <DeviceDetailsTab {...props} />;
  }, [props.isWex, props.mode]);

  return (
    <div data-testid="details-main">
      <DetailsAccordion {...props} />

      {props.inkWarnings.length > 0 && <SuppliesWarning inks={props.inkWarnings} />}

      {showDetailsTab && renderMemoDeviceDetailsTab}
    </div>
  );
};
