import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { refinedUseGetDeviceInfo } from './deviceCacheRefinery';
import { getProps, isMockUpMode } from 'src/utils/commonMethods';
import getDeviceInfoMock from 'src/mock/deviceCache/get-device-info.json';
import axios from 'axios';

export const deviceCacheAPI: any = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.deviceCache);
  const { demoEnabled, apiPath } = getProps();

  const getDeviceInfo = async (deviceId) => {
    let response, error;

    if (demoEnabled) {
      const url = apiPath('demo', 'generic', 'printMfeCache', 'deviceCache_get_with_deviceId');
      await axios.get(url).then((res) => {
        response = refinedUseGetDeviceInfo(res.data);
      });
      return { response, error };
    }

    if (isMockUpMode()) {
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      response = refinedUseGetDeviceInfo(getDeviceInfoMock);

      // await getMockUpData('deviceCache/get-device-info').then((data) => {
      //   response = refinedUseGetDeviceInfo(data);
      // });
      return { response, error };
    }
    await client
      .show(deviceId)
      .then((res) => {
        response = refinedUseGetDeviceInfo(res.data);
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getDeviceInfo,
  };
};
