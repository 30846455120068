import styled from 'styled-components';
import React from 'react';
import tokens from '@veneer/tokens';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { t, capitalizeFirstLetter, getStatus, toTitleCase } from 'src/utils/commonMethods';
import {
  Button,
  IconCheckmarkCircle,
  IconInfo,
  IconMinusCircle,
  IconWarningAlt,
  IconXCircle,
  Tooltip,
} from '@veneer/core';

type TDeviceStatusProps = {
  status: {
    acceptingJobs: boolean;
    printerStateSeverity: string;
    printerState: string;
    connectionState: string;
  };
};

const DeviceStatus = (props: TDeviceStatusProps) => {
  const status = getStatus(props.status);
  const { isWex, mode } = useShellRootContext();

  const getStatusText = () => {
    switch (status) {
      case 'UNKNOWN':
        return t('info.unknown');
      case 'ERROR':
        return t('info.error');
      case 'WARNING':
        return t('info.warning');
      case 'READY':
        return t('info.ready');
    }
  };

  const wexIcon = {
    UNKNOWN: {
      leadingIcon: (
        <IconInfo filled={true} customStyle={{ color: '#ffffff', width: '14px !important' }} />
      ),
      width: '92px',
      colorScheme: undefined,
    },
    ERROR: {
      leadingIcon: (
        <IconMinusCircle
          filled={true}
          customStyle={{ color: '#ffffff', width: '14px !important' }}
        />
      ),
      colorScheme: 'negative',
      width: '70px',
    },
    WARNING: {
      leadingIcon: <IconWarningAlt filled={true} customStyle={{ width: '12px !important' }} />,
      colorScheme: 'warning',
      width: '88px',
    },
    READY: {
      leadingIcon: <IconCheckmarkCircle filled={true} customStyle={{ width: '14px !important' }} />,
      colorScheme: 'positive',
      width: '78px',
    },
  };

  const getStatusIcon = () => {
    if (isWex) {
      return (
        <Button
          colorScheme={wexIcon[status].colorScheme}
          leadingIcon={wexIcon[status].leadingIcon}
          customStyle={{
            minWidth: wexIcon[status].width,
            width: wexIcon[status].width,
            height: '24px',
            maxWidth: '256px',
            borderRadius: '4px',
            gap: '4px',
            padding: '4px 12px 4px 8px',
            pointerEvents: 'none',
            ...(status === 'UNKNOWN' && { backgroundColor: '#666666' }),
          }}
        >
          <WXText>{toTitleCase(status)}</WXText>
        </Button>
      );
    }

    switch (status) {
      case 'UNKNOWN':
        return (
          <IconInfo
            data-testid={'device-status-state-info-icon'}
            color="gray6"
            filled={true}
            size={24}
          />
        );
      case 'ERROR':
        return (
          <IconXCircle
            data-testid={'device-status-state-xcircle-icon'}
            color="red6"
            filled={true}
            size={24}
          />
        );
      case 'WARNING':
        return (
          <IconWarningAlt
            data-testid={'device-status-state-warning-icon'}
            color="darkOrange5"
            filled={true}
            size={24}
          />
        );
      case 'READY':
        return (
          <IconCheckmarkCircle
            data-testid={'device-status-state-check-circle-icon'}
            color="green6"
            filled={true}
            size={24}
          />
        );
    }
  };
  return (
    <Wrapper data-testid={'device-status'}>
      <Content>
        <Title data-testid={'status-text'}>
          <Text>{t('info.status')}</Text>
          <Tooltip content={t('message.tooltip')} placement="bottom">
            <IconInfo size={18} />
          </Tooltip>
        </Title>

        <StatusWrapper isWex={isWex} mode={mode}>
          {getStatusIcon()}
          {!isWex && <TextLabel>{capitalizeFirstLetter(getStatusText())}</TextLabel>}
        </StatusWrapper>
      </Content>
    </Wrapper>
  );
};

export default DeviceStatus;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 10px 0;
  min-width: 90px;

  .status-icon {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }
`;

const TextLabel = styled.label`
  margin-top: -2px;
`;

const Content = styled.div``;

const Title = styled.div`
  line-height: 16px;
  font-size: 14px;
  color: var(--gray500);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.span`
  margin-right: 4px;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3px;
  ${(props) => {
    if (props.isWex && props.mode == 'dark') return `color: ${tokens.color.white};`;
    else return 'color: #333333;';
  }}
  font-weight: bold;
  font-size: 16px;

  svg {
    margin-top: -2px;
    margin-right: 5px;
  }
`;

const WXText = styled.div`
  font-family: Forma DJR UI !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  text-align: center !important;
`;
