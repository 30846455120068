import { service } from 'src/utils/constants';
import { getProps } from 'src/utils/commonMethods';
import {
  Stack,
  CollectionClient,
  DeviceCacheClient,
  DeviceShadowApiClient,
  SubscriptionsClient,
  FleetProxyConnectorClient,
} from '@jarvis/react-mfe-component/dist/src';

export const useApiClient = () => {
  const localProps = getProps();
  const stack = localProps.stack;
  const authProvider = localProps.authProvider;

  const getApiClient = (serviceName) => {
    let client;

    switch (serviceName) {
      case service.collection:
        client = new CollectionClient(stack, authProvider);
        break;

      case service.deviceCache:
        client = new DeviceCacheClient(stack, authProvider);
        break;

      case service.deviceShadow:
        client = new DeviceShadowApiClient(stack, authProvider);
        break;

      case service.subscriptions:
        const getStackPrefixSubscription = (stack: Stack): string => {
          switch (stack) {
            case Stack.dev:
            case Stack.pie:
              return 'daily';
            case Stack.stage:
              return 'staging';
            case Stack.prod:
              return '';
            default:
              return '';
          }
        };
        const getSubscriptionsApiUrl = (
          hostPrefix: string,
          basePath: string,
          type?: string,
        ): string => {
          const stackPrefix = getStackPrefixSubscription(stack);
          const domain = type === 'subscription' ? 'cc-gw.hpcloud.hp.com/' : 'us1.api.ws-hp.com';

          return (
            'https://' +
            (hostPrefix.length ? hostPrefix + '.' : '') +
            (stackPrefix.length ? stackPrefix + '-' : '') +
            domain +
            basePath
          );
        };
        const subscriptionsApiEndPoint = getSubscriptionsApiUrl(
          '',
          'cors/v1/subscriptions',
          'subscription',
        );

        client = new SubscriptionsClient(subscriptionsApiEndPoint, authProvider);
        break;
      case service.fleetProxy:
        client = new FleetProxyConnectorClient(stack, authProvider);
        break;
    }

    return client;
  };

  return {
    getApiClient,
  };
};

export default useApiClient;
