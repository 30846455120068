import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { getProps, isMockUpMode } from 'src/utils/commonMethods';
import { refinedUseGetCollectionByEntityId } from './collectionRefinery';
import getCollectionByEntityIdMock from 'src/mock/collection/get-collection-by-entity-id.json';
import axios from 'axios';

export const collectionAPI = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.collection);
  const { demoEnabled, apiPath } = getProps();

  const getCollectionByEntityId = async (deviceId) => {
    let error,
      response = {};
    if (demoEnabled) {
      const url = apiPath('demo', 'generic', 'printMfeCache', 'collections_get_entity_mgnt');
      await axios.get(url).then((res) => {
        response = refinedUseGetCollectionByEntityId(res.data).contents;
      });
      return { response, error };
    }

    if (isMockUpMode()) {
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      response = refinedUseGetCollectionByEntityId(getCollectionByEntityIdMock).contents;

      // await getMockUpData('collection/get-collection-by-entity-id').then((data) => {
      //   response = refinedUseGetCollectionByEntityId(data).contents;
      // });
      return { response, error };
    }

    await client
      .getCollectionByEntityId('devices', deviceId)
      .then((res) => {
        response = refinedUseGetCollectionByEntityId(res.data).contents;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getCollectionByEntityId,
  };
};
