import styled from 'styled-components';
import React from 'react';
import tokens from '@veneer/tokens';
import { dateFormat } from 'src/utils/constants';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { t, getFormattedDate, capitalizeFirstLetter } from 'src/utils/commonMethods';

type TDeviceStatusUpdatedProps = {
  lastUpdatedAt: string;
};

const DeviceUpdated = (props: TDeviceStatusUpdatedProps) => {
  const { isWex, mode } = useShellRootContext();

  const getDetail = () => {
    const formattedLastUpdated = getFormattedDate(dateFormat.default, props.lastUpdatedAt);

    return (
      <Value isWex={isWex} mode={mode}>
        <StatusUpdatedDetail>{capitalizeFirstLetter(formattedLastUpdated)}</StatusUpdatedDetail>
      </Value>
    );
  };

  return (
    <Wrapper data-testid={'device-updated'}>
      {!isWex && <BorderSeparator />}

      <Content>
        <Title data-testid={'lastSynced-text'}>
          <Text>{t('info.lastSynced')}</Text>
        </Title>

        {getDetail()}
      </Content>
    </Wrapper>
  );
};

export default DeviceUpdated;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 10px 0;
  min-width: 90px;
`;

const Content = styled.div``;

const BorderSeparator = styled.div`
  display: inline-block;
  border-left: 2px solid rgba(33, 33, 33, 0.1);
  height: 32px;
  margin-right: 10px;
`;

const Title = styled.div`
  line-height: 16px;
  font-size: 14px;
  color: var(--gray500);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Value = styled.div`
  padding-top: 3px;
  display: flex;
  align-items: center;
  ${(props) => {
    if (props.isWex && props.mode == 'dark') return `color: ${tokens.color.white};`;
    else return 'color: #333333;';
  }}

  font-weight: bold;
  font-size: 16px;
`;

const Text = styled.span`
  margin-right: 4px;
`;

const StatusUpdatedDetail = styled.div`
  line-height: 24px;
`;
