import styled, { css } from 'styled-components';
import React from 'react';
import { isEmptyData, t } from 'src/utils/commonMethods';
import { IconCheckmarkCircle, IconWarningAlt, IconXCircle, Tooltip } from '@veneer/core';
import { useStoreState } from 'src/store/useStoreState';

export const OtherSupplies = () => {
  const STATUS = {
    maintenanceCartridge: t('other_supplies.maintenance_cartridge'),
  };
  const { detailsState } = useStoreState();
  const { consumables } = detailsState;
  const data = consumables.otherSupplies;

  const getIcon = (type, reasons) => {
    switch (type) {
      case 'error':
        let errorMsg = '';
        for (const reason of reasons) {
          if (reason == 'missingError') {
            errorMsg += t('other_supplies.missing_error');
          }
          if (reason == 'unknownError') {
            errorMsg += t('other_supplies.unknown_error');
          }
          errorMsg += '\n';
        }
        return (
          <Tooltip content={errorMsg} placement="bottom">
            <IconXCircle data-testid={'other-supplies-icon-xcircle'} color="red6" filled={true} />
          </Tooltip>
        );
      case 'warning':
        return (
          <IconWarningAlt
            data-testid={'other-supplies-icon-warning-alt'}
            color="darkOrange5"
            filled={true}
          />
        );
      default:
        return (
          <IconCheckmarkCircle
            data-testid={'other-supplies-icon-checkmark-circle'}
            color="hunterGreen6"
            filled={true}
          />
        );
    }
  };

  return (
    <Wrapper data-testid="other-supplies">
      {!isEmptyData(data) &&
        data.map((it) => (
          <MediaItem key={`other-supplies-${it.type}`}>
            <GrayBox>
              <Diagonal />
            </GrayBox>
            <IconWrapper>{getIcon(it.status, it.stateReason)}</IconWrapper>
            <TextWrapper enable={it.percentLifeDisplay}>
              <Status>{STATUS[it.type]}</Status>
              {/*TODO: Apply Translation*/}
              {/*<Detail status={it.status}>{`${t('other_supplies.description')}: ${it.percentLifeDisplay}%`}</Detail>*/}
              <Detail status={it.status}>{`${t('other_supplies.capacity')}: ${
                it.percentLifeDisplay
              }%`}</Detail>
            </TextWrapper>
          </MediaItem>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  //justify-content: center;
  align-items: self-start;
  flex-wrap: wrap;
`;

const MediaItem = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const Diagonal = styled.div`
  width: 1px;
  height: 32px;
  background: #adadad;
  transform: rotate(-45deg);
  position: absolute;
  left: 51%;
  top: -20%;
`;

const GrayBox = styled.div`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  background: #f3f3f3;
  border: 0.5px solid #adadad;
  position: relative;
`;

const IconWrapper = styled.div`
  margin: 0 8px;
`;

const Status = styled.span`
  font-family: var(--fontFamilyBase);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const TEXT_COLOR = {
  error: css`
    color: #be1313;
  `,
  warning: css`
    color: #d06702;
  `,
  ok: css`
    color: #212121;
  `,
};

const Detail = styled.span`
  ${(props) => TEXT_COLOR[props.status]}

  height: 16px;
  margin-top: 4px;

  font-family: var(--fontFamilyBase);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Detail} {
    display: ${(props) => props.enable == -1 && 'none'};
  }

  ${Status} {
    line-height: unset;
  }
`;
