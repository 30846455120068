import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
/**
 * Fleet Proxy API
 */
export const deviceShadowAPI = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.deviceShadow);

  const getDeviceDiscoveryWithResourceName = async (deviceId, resourceName) => {
    let response, error;

    await client
      .getDeviceDiscoveryWithResourceName({
        cloudId: deviceId,
        resourceName: resourceName,
      })
      .then((res) => {
        response = res;
      })
      .catch((err) => {
        error = err;
      });
    return { response, error };
  };

  return {
    getDeviceDiscoveryWithResourceName,
  };
};
