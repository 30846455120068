import styled, { css } from 'styled-components';
import React from 'react';
import { COLOR_CODE } from 'src/utils/constants';
import { isEmptyData } from 'src/utils/commonMethods';
import { StatusTooltip } from '../atom';
import { useStoreState } from 'src/store/useStoreState';

export const PrintHead = () => {
  const { detailsState } = useStoreState();
  const { consumables } = detailsState;
  const data = consumables.printHead;
  const sortById = (arr) => {
    return arr.sort((a, b) => a.id - b.id);
  };

  return (
    <Wrapper data-testid="print-head">
      {!isEmptyData(data) &&
        sortById(data).map((printHead, idx) => (
          <BoxWrapper key={`print-head-${idx}`}>
            <StatusTooltip status={printHead.status} stateReason={printHead.stateReason} />

            <Index>{idx + 1}</Index>
            <Rectangle status={printHead.status} cnt={printHead.colors.length}>
              {printHead.colors.map((color) => (
                <CommonHeadPiece key={`print-head-color-${color}`} color={COLOR_CODE[color]} />
              ))}
            </Rectangle>
          </BoxWrapper>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 164px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
`;

const Index = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px;
`;

const CommonHeadPiece = styled.div`
  margin-bottom: 7px;
  border: 1px solid #585858;
  height: 20px;
  width: 11px;
  background: ${(props) => props.color};
`;

const BACKGROUND = {
  default: css`
    background: repeating-linear-gradient(-45deg, #c4c4c4, #c4c4c4 9px, #585858 10px);
    border: 1px solid black;
  `,
  empty: css`
    background: white;
    border: 1px dashed black;

    ${CommonHeadPiece} {
      background: white;
      border: none;
    }
  `,
};

const Rectangle = styled.div`
  height: 99px;
  width: 34px;

  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: self-end;

  ${CommonHeadPiece} {
    width: ${({ cnt }) => `${20 / cnt}px`};
  }

  ${(props) => BACKGROUND[props.status === 'error' ? 'empty' : 'default']}
`;
