import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { t } from 'src/utils/commonMethods';
import { accordionHelper } from 'src/veneerHelper';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { Supplies, DeviceInfo } from 'src/components/organism';
import { Accordion, Button, IconExternalLink, IconProxy, Tag } from '@veneer/core';

type TDetailsAccordionProps = {
  details: any;
  deviceId: string;
  baseurl: string;
};

const DetailsAccordion = (props: TDetailsAccordionProps) => {
  const { accordionState, accordionSetState, accordionFunc } = accordionHelper();
  const { ecpDeviceV55 } = useShellRootContext();
  const [dynamicHeight, setDynamicHeight] = useState(true);
  const modelName = props.details.identity.makeAndModel.name;
  const isPolicyTab = props.baseurl?.includes('/policies');
  const isShowProxyTag = props.details.connTypes.includes('fleet-proxy');

  useEffect(() => {
    setDynamicHeight(false);
    createAccordionItems();
  }, []);

  const isNewDevice = () => {
    if (!props.details.timestamp.addedDate) {
      return false;
    }
    const currentDate = moment();
    const elapseTime = currentDate.diff(props.details.timestamp.addedDate, 'days');
    return !isNaN(elapseTime) && elapseTime <= 5;
  };

  const getContents = () => {
    return (
      <DetailsSection>
        <DeviceInfo {...{ isPolicyTab }} />
        <Supplies createAccordionItems={createAccordionItems} />
      </DetailsSection>
    );
  };

  const headerEndArea = () => {
    return (
      <HeaderEndWrapper>
        <Button
          appearance="ghost"
          leadingIcon={<IconExternalLink />}
          customStyle={{ color: '#027aae', fontWeight: 400, fontSize: '16px', lineHeight: '20px' }}
        >
          {t('label.open_ews')}
        </Button>
      </HeaderEndWrapper>
    );
  };

  const createAccordionItems = () => {
    const updatedAccordion = [
      {
        id: 'device-details-info-section',
        expanded: true,
        header: {
          centralArea: (
            <HeaderWrapper>
              <HeaderLeadingWrapper>
                <Title data-testid="details-accordion__model-name">{modelName}</Title>
                {isShowProxyTag && (
                  <Tag
                    data-testid={'proxy-tag'}
                    customStyle={CustomTagStyle}
                    label="Proxy"
                    leadingIcon={<IconProxy />}
                  />
                )}
                {isNewDevice() && (
                  <Tag
                    data-testid={'new-device-tag'}
                    customStyle={CustomTagStyle}
                    label="New"
                    colorScheme="hpBlue"
                  />
                )}
              </HeaderLeadingWrapper>
              {ecpDeviceV55 && headerEndArea()}
            </HeaderWrapper>
          ),
        },
        content: getContents(),
      },
    ];

    accordionSetState.setItems(updatedAccordion);
  };

  return (
    <Wrapper data-testid="details-accordion" dynamicHeight={dynamicHeight}>
      <Accordion
        id="accordion-for-detail-info"
        items={accordionState.items}
        onExpand={accordionFunc.handleExpand}
        onCollapse={accordionFunc.handleCollapse}
      />
    </Wrapper>
  );
};

export default DetailsAccordion;

const Wrapper = styled.div`
  min-height: ${(props) => (props.dynamicHeight ? '300px' : 'auto')};
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  div:nth-child(1) {
    width: min(359px, 100%);
  }

  div:nth-child(2) {
    margin: 0 18px 0 10px;
  }
`;

const DetailsSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Title = styled.div`
  width: fit-content !important;
  font-size: 20px;
  margin-left: 20px;
  line-height: 28px;
`;

const HeaderLeadingWrapper = styled.div`
  width: fit-content !important;
  display: flex;
  align-items: center;
`;
const HeaderEndWrapper = styled.div`
  display: flex;
  padding-right: 24px;
`;

const CustomTagStyle = css`
  margin: 0 0 0 8px !important;
`;
