import styled, { css } from 'styled-components';
import React from 'react';
import { StatusTooltip } from 'src/components/atom';
import { isEmptyData, t } from 'src/utils/commonMethods';
import { COLOR_CODE, BORDER, LABEL } from 'src/utils/constants';
import tokens from '@veneer/tokens';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { useStoreState } from 'src/store/useStoreState';

type TCartridgeProps = {
  minHeight: number;
};

export const Cartridge = (props: TCartridgeProps) => {
  const { isWex, mode } = useShellRootContext();
  const { detailsState } = useStoreState();
  const { consumables } = detailsState;
  const data = consumables.ink;
  const checkUsedRefillCounterfeitState = (state) => {
    const warningStates = [
      'isAlteredWarning',
      'counterfeitWarning',
      'refilledWarning',
      'refilledCartridgeInstalledWarning',
      'alteredCartridgeInstalledWarning',
      'alteredCartridgeIdentifiedWarning',
      'alteredCartridgeUsedForStartUpWarning',
    ];
    return warningStates.filter((st) => st === state).length > 0;
  };

  const getPercentage = (status, percentage, stateReason) => {
    if (status === 'error' && stateReason != 'subscriptionSupplyNotAllowedError') {
      return -1;
    }
    if (status === 'warning' && stateReason == 'veryLowWarning') {
      return 10;
    }
    if (status === 'warning' && checkUsedRefillCounterfeitState(stateReason)) {
      return 10;
    }
    return percentage;
  };

  return (
    <Wrapper data-testid={'ink'} minHeight={props.minHeight}>
      {!isEmptyData(data) && (
        <>
          <InkGroup>
            {data.map((ink) => (
              <InkWrapper key={ink.color}>
                <StatusTooltip status={ink.status} stateReason={ink.stateReason} />

                <TonerBackground>
                  <BorderBox
                    data-testid={`cartridge-border-box-color-${ink.color}`}
                    percentage={getPercentage(ink.status, ink.percentLifeDisplay, ink.stateReason)}
                    status={ink.status}
                  />
                  <TonerBasis
                    color={ink.color}
                    percentage={getPercentage(ink.status, ink.percentLifeDisplay, ink.stateReason)}
                  />
                  <TonerLevel1
                    color={ink.color}
                    percentage={getPercentage(ink.status, ink.percentLifeDisplay, ink.stateReason)}
                  />
                  <TonerLevel2
                    color={ink.color}
                    percentage={getPercentage(ink.status, ink.percentLifeDisplay, ink.stateReason)}
                  />
                  <TonerLevel3
                    color={ink.color}
                    percentage={getPercentage(ink.status, ink.percentLifeDisplay, ink.stateReason)}
                  />
                  <TonerLabel color={ink.color}>{ink.color}</TonerLabel>
                </TonerBackground>
              </InkWrapper>
            ))}
          </InkGroup>
          {/*TODO: Apply Translation*/}
          {/*<Message data-testid="device-security-label">{t('cartridge.description')}</Message>*/}
          <Message data-testid="device-security-label" isWex={isWex} mode={mode}>
            {t('label.estimated_supply_label')}
          </Message>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  min-height: ${({ minHeight }) => minHeight}px;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 2;
  padding-top: 10px;
  padding-bottom: 30px;

  @media (max-width: 1111px) {
    min-height: auto;
  }
`;

const InkGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const InkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 14px;
  justify-content: flex-end;
  align-items: center;
`;

const Message = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  ${(props) => {
    if (props.isWex && props.mode == 'dark') return `color: ${tokens.color.white};`;
    else return 'color: #404040;';
  }}
  margin-top: 18px;
`;

const TonerLabel = styled.div`
  width: 16px;
  height: 16px;

  font-size: 10px;
  line-height: 16px;
  text-align: center;

  border: 1px solid #f3f3f3;
  border-radius: 8px;

  margin-top: 110px;

  background: ${({ color }) => COLOR_CODE[color]};
  color: ${({ color }) => (color == 'K' ? LABEL['white'] : LABEL['black'])};
`;

const BorderBox = styled.div`
  height: 99px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 9px;
  z-index: 10;

  border: ${({ percentage, status }) => {
    if (status === 'warning') return BORDER['solidOrange'];
    if (status === 'error' || percentage === 0) return BORDER['redDotted'];
  }};
`;

const TonerBasis = styled.div`
  position: absolute;
  width: 16px;
  //height: 60px;
  border-radius: 0 0 8px 8px;
  //margin-top: 90px;

  background: ${({ color }) => COLOR_CODE[color]};
  height: ${({ percentage }) => {
    if (percentage >= 91) return '100px';
    if (percentage > 0 && percentage < 10) return '10px';
    return `${percentage}px`;
  }};

  ${({ percentage }) => {
    switch (true) {
      case percentage >= 91:
        return css`
          margin-top: 0 !important;
          border-radius: 8px !important;
        `;
      case percentage == 20:
        return css`
          margin-top: 80px !important;
        `;
      case percentage >= 10:
        return css`
          margin-top: ${100 - percentage}px !important;
        `;
      case percentage > 0:
        return css`
          margin-top: 90px !important;
        `;
      default:
        return css`
          visibility: hidden !important;
        `;
    }
  }}
}
`;

const TonerLevelBasis = styled.div`
  position: absolute;
  width: 16px;
  height: 3px;
  background: #f3f3f3;
  z-index: 1;
`;

const TonerLevel1 = styled(TonerLevelBasis)`
  opacity: 0.85;
  margin-top: 90px;
  order: 0;

  ${({ percentage }) => {
    switch (true) {
      case percentage >= 91:
        return css`
          visibility: hidden !important;
        `;
      case percentage == 20:
        return css`
          margin-top: 80px !important;
        `;
      case percentage >= 10:
        return css`
          margin-top: ${100 - percentage}px !important;
        `;
      case percentage > 0:
        return css`
          margin-top: 90px !important;
        `;
      default:
        return css`
          visibility: hidden !important;
        `;
    }
  }}
`;
const TonerLevel2 = styled(TonerLevelBasis)`
  opacity: 0.65;
  margin-top: 93px;
  order: 1;

  ${({ percentage }) => {
    switch (true) {
      case percentage >= 91:
        return css`
          visibility: hidden !important;
        `;
      case percentage == 20:
        return css`
          margin-top: 83px !important;
        `;
      case percentage >= 10:
        return css`
          margin-top: ${100 - percentage + 3}px !important;
        `;
      case percentage > 0:
        return css`
          margin-top: 93px !important;
        `;
      default:
        return css`
          visibility: hidden !important;
        `;
    }
  }}
`;
const TonerLevel3 = styled(TonerLevelBasis)`
  opacity: 0.35;
  margin-top: 96px;
  order: 2;

  ${({ percentage }) => {
    switch (true) {
      case percentage >= 91:
        return css`
          visibility: hidden !important;
        `;
      case percentage == 20:
        return css`
          margin-top: 86px !important;
        `;
      case percentage >= 10:
        return css`
          margin-top: ${100 - percentage + 6}px !important;
        `;
      case percentage > 0:
        return css`
          margin-top: 96px !important;
        `;
      default:
        return css`
          visibility: hidden !important;
        `;
    }
  }}
`;

const TonerBackground = styled.div`
  width: 16px;
  height: 100px;
  background: #f3f3f3;
  border-radius: 8px;
  margin-bottom: 26px;
  position: relative;
`;
